import React, { useEffect, useRef, useState } from "react";
import Peer from "peerjs";

export default function Meet({ socket, room }) {
    const user = JSON.parse(localStorage.getItem('userDetails'));
    const webcamRef = useRef(null);
    const remoteStreamRefs = useRef({});
    const [video, setVideo] = useState(false);
    const [audio, setAudio] = useState(false);
    const [hide, setHide] = useState(false);
    const [localStream, setLocalStream] = useState(null);
    const [users, setUsers] = useState([]);
    const [peer, setPeer] = useState(null);
    const [remoteVideos, setRemoteVideos] = useState([]);

    useEffect(() => {
        const newPeer = new Peer(user.username, {
            path: '/peerjs',
            port: '443',
            host: 'api.hiprolab.com',
            secure: true,
        });

        newPeer.on('open', (peerId) => {
            socket.emit('peer-users', peerId);
        });

        newPeer.on('call', (call) => {
            if (call.peer !== user.username) {
                if (localStream) {
                    call.answer(localStream);
                } else {
                    call.answer(); 
                }
                call.on('stream', (incomingStream) => {
                    if (remoteStreamRefs.current[call.peer]) {
                        remoteStreamRefs.current[call.peer].current.srcObject = incomingStream;
                    }
                });
            }
        });

        setPeer(newPeer);

        return () => {
            newPeer.disconnect();
            newPeer.destroy();
        };
    }, [user.username]);

    useEffect(() => {
        if (peer) {
            socket.on('sended', (id) => {
                if (!remoteStreamRefs.current[id]) {
                    const videoRef = React.createRef();
                    remoteStreamRefs.current[id] = videoRef;

                    setRemoteVideos((prevRemoteVideos) => [
                        ...prevRemoteVideos,
                        <div key={id}>
                            <video
                                id={id}
                                ref={videoRef}
                                playsInline
                                autoPlay
                                className="border-top border-2 border-dark w-100"
                                height={173}
                            />
                        </div>,
                    ]);
                }

                if (localStream) {
                    const call = peer.call(id, localStream);
                    call.on('stream', (incomingStream) => {
                        if (remoteStreamRefs.current[id]) {
                            remoteStreamRefs.current[id].current.srcObject = incomingStream;
                        }
                    });
                }
            });

            return () => {
                socket.off('sended');
            };
        }
    }, [peer, localStream]);

    const initializeSocket = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
            webcamRef.current.srcObject = stream;
            setLocalStream(stream);

            if (users.length && peer) {
                users.forEach((id) => {
                    if (id !== user.username) {
                        const call = peer.call(id, stream);
                        call.on('stream', (incomingStream) => {
                            if (remoteStreamRefs.current[id]) {
                                remoteStreamRefs.current[id].current.srcObject = incomingStream;
                            }
                        });
                    }
                });
            }
        } catch (error) {
            console.error('Error accessing media devices.', error);
        }
    };

    useEffect(() => {
        socket.on('get-peers', (data) => {
            setUsers(data);

            data.forEach((id) => {
                if (!remoteStreamRefs.current[id] && id !== user.username) {
                    const videoRef = React.createRef();
                    remoteStreamRefs.current[id] = videoRef;

                    setRemoteVideos((prevRemoteVideos) => [
                        ...prevRemoteVideos,
                        <div key={id}>
                            <video
                                id={id}
                                ref={videoRef}
                                playsInline
                                autoPlay
                                className="border-top border-2 border-dark w-100"
                                height={173}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}

                            />
                            <div
                                className="text-center"
                                style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    width: '100%',
                                    zIndex: 2,
                                }}
                            >
                                <span className="bg-dark px-3 rounded-pill text-white opacity-75 w-auto">{id}</span>
                            </div>
                        </div>,
                    ]);
                }
            });
        });

        return () => {
            socket.off('get-peers');
        };
    }, [user.username]);

    const toggleVideo = async () => {
        setVideo((prevVideo) => !prevVideo);

        if (!localStream) {
            await initializeSocket();
        } else {
            localStream.getTracks().forEach((track) => {
                if (track.readyState === 'live') {
                    track.stop();
                }
            });

            setLocalStream(null);
        }
    };

    const toggleAudio = () => {
        setAudio((prevAudio) => !prevAudio);
    };

    const toggleHide = () => {
        setHide((prevHide) => !prevHide);
    };
    return (
        <div>
            <div className="shadow round-top round-bottom">
                <div className={`px-4 text-center d-flex gap-2 round-top ${hide ? 'round-bottom' : ''}`} style={{ backgroundColor: '#272C36', marginTop: '-5px' }}>
                    <div className="text-center pt-2 w-100">
                        <button className="btn p-2 text-success btn-icon">
                            <i class="fa-solid fa-comments"></i>
                        </button>
                        <button className="btn p-2 text-danger btn-icon" onClick={toggleAudio}>
                            {audio ? <i className="fa-solid fa-microphone fa-lg"></i> : <i className="fa-solid fa-microphone-slash fa-lg"></i>}
                        </button>
                        <button className="btn p-2 text-info btn-icon" onClick={toggleVideo}>
                            {video ? <i className="fa-solid fa-video fa-lg"></i> : <i className="fa-solid fa-video-slash fa-lg"></i>}
                        </button>
                    </div>
                </div>
                <div
                    className={`${hide ? 'd-none' : 'd-flex'} flex-column text-white round-bottom`}
                    style={{ backgroundColor: '#1f232b', position: 'relative' }}
                >
                    <div className="d-flex" style={{ position: 'relative', height: '173px' }}>
                        {video ? (
                            <video
                                ref={webcamRef}
                                autoPlay
                                playsInline
                                className="border-top border-2 border-dark"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        ) : (
                            !hide && <i className="fa-solid fa-video-slash fa-lg m-auto"></i>
                        )}
                        <div
                            className="text-center"
                            style={{
                                position: 'absolute',
                                bottom: '10px',
                                width: '100%',
                                zIndex: 2,
                            }}
                        >
                            <span className="bg-dark px-3 rounded-pill text-white opacity-75 w-auto">
                                {user.displayName}
                            </span>
                        </div>
                    </div>

                    {remoteVideos}
                </div>
            </div>
        </div>
    );
}
