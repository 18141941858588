import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
// import "./chart.css";
// import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Nav2 from "./layouts/nav/index2";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import LearningPath from "./components/LearningPath/LearningPath";
import Practicum from "./components/Practicum/admin/Practicum";
import PracticumDetail from "./components/Practicum/admin/PracticumDetail";
import PracticumDetailStudent from "./components/Practicum/student/PracticumDetailStudent";
import PracticumStudent from "./components/Practicum/student/StudentSchedule";
import PracticumStart from "./components/Practicum/student/PracticumStart";
import StudentTest from "./components/StudentTest/StudentTest";
import Schedule from "./components/Schedule/Schedule";
import Monitoring from "./components/monitoring";
import UserGroup from "./components/UserGroup/UserGroup";
import UserManagement from "./components/UserManagement/UserManagement";
import Hero from "./components/Hero/Hero";
import Fancybox from "./components/Fancybox/Fancybox";
import Project from "./components/Project/Project";
import Documentation from "./components/Documentation/Documentation";

/// Pages
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import { StudentTestDetail } from "./components/StudentTest/StudentTestDetail";

const Markup = () => {
  const allRoutes = [
    // dashboard
    { url: "admin/dashboard", component: <Home /> },
    // learning-path
    { url: "admin/learning-path", component: <LearningPath /> },
    /// practicum
    { url: "admin/practicum", component: <Practicum /> },
    { url: "admin/practicum/:id", component: <PracticumDetail /> },
    /// student-test
    { url: "admin/student-test", component: <StudentTest /> },
    { url: "admin/student-test/:id", component: <StudentTestDetail /> },
    /// schedule
    { url: "admin/schedule", component: <Schedule /> },
    /// monitoring
    { url: "admin/monitoring", component: <Monitoring /> },
    /// user-group
    { url: "admin/user-group", component: <UserGroup /> },
    /// user-management
    { url: "admin/user-management", component: <UserManagement /> },
    /// web-management
    { url: "admin/web-management/hero", component: <Hero /> },
    { url: "admin/web-management/fancybox", component: <Fancybox /> },
    { url: "admin/web-management/project", component: <Project /> },
    { url: "admin/web-management/documentation", component: <Documentation /> },
  ];

  const studentRoutes = [
    // dashboard
    { url: "student/dashboard", component: <Home /> },
    /// practicum
    { url: "student/practicum", component: <PracticumStudent /> },
    { url: "student/practicum/:id", component: <PracticumDetailStudent /> },
  ];

  const practicumStartRoutes = [
    /// practicum
    { url: "student/practicum/start", component: <PracticumStart /> },
  ];

  return (
    <>
      <Routes>
        <Route element={<Layout7 />}>
          {allRoutes.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} element={data.component} />
          ))}
        </Route>
        <Route element={<LayoutStudent />}>
          {studentRoutes.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} element={data.component} />
          ))}
        </Route>
        <Route element={<LayoutPracticumStart />}>
          {practicumStartRoutes.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} element={data.component} />
          ))}
        </Route>
      </Routes>
      <Setting />
      <ScrollToTop />
    </>
  );
};

function Layout7() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer changeFooter="out-footer" />
    </div>
  );
}

function LayoutStudent() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav2 />
      <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer changeFooter="out-footer" />
    </div>
  );
}

function LayoutPracticumStart() {
  return (
    <div id="main-wrapper vh-100 overflow-hidden" className="show">
      <div className="content-body ms-0 pt-0 overflow-hidden">
        <div className="container-fluid ">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Markup;
