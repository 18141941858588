import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CkEditorBlog from "./CkEditorBlog";
import { useDispatch } from "react-redux";
import { updatePostAction } from "../../../store/actions/PostActions";

const EditModal = forwardRef(({ editData, onEditSuccess }, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));

  const [formData, setFormData] = useState({
    name: "",
    des: "",
  });

  const [title, setTitle] = useState("");
  const [des, setDes] = useState("<p></p>");
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (editData) {
      setTitle(editData.name); // Set the title from editData
      setDes(editData.des); // Set the description from editData
    }
  }, [editData]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const submit = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();

    if (selectedFile) {
      updatedFormData.append("image", selectedFile);
      updatedFormData.append("oldImage", editData.image);
    }
    updatedFormData.append("name", title);
    updatedFormData.append("des", des);

    if (editData && editData.id) {
      // Dispatch edit action for the specific post
      dispatch(updatePostAction(`/hero/update/`, updatedFormData, editData.id))
        .then(() => {
          onEditSuccess(); // Panggil callback untuk memberitahu Hero bahwa update berhasil
        })
        .catch((error) => {
          console.error("Error updating post:", error);
        });
    }

    setModalBox(false);
  };
  return (
    <>
      <Modal onHide={setModalBox} size="xl" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Hero</h5>
            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <div>
                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      textAlign: "center",
                      lineHeight: "220px",
                      border: "1px dashed #ccc",
                      borderRadius: "4px",
                    }}
                  >
                    Upload Gambar (450x250)
                  </label>
                  <input id="file-upload" type="file" style={{ display: "none" }} onChange={handleFileChange} />
                  {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-3">
                  <label htmlFor="basic-url" className="form-label d-block">
                    Deskripsi
                  </label>
                  <CkEditorBlog data={des} setData={setDes} />
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Title"
                    value={title} // Use state to control input value
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setModalBox(false)}>
              Tutup
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => submit(e)}>
              Simpan
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default EditModal;
