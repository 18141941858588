import React, { useState, useReducer, useEffect, useRef } from "react";
import { Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import BasicModal from "./BasicModal";
import { useDispatch } from "react-redux";
import { deletePostAction, getPostsAction } from "../../../store/actions/PostActions";

export const Activities = ({ id, refer }) => {
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const childRef = useRef();
  useEffect(() => {
    fetchActivities(id)
  }, [id])
  const destroyActivity = async (e, deleteId) => {
    e.preventDefault();
    const response = await dispatch(deletePostAction('/question/delete/', deleteId))
    if (response) {
      fetchActivities(id)
    }
  }
  const fetchActivities = async (subId) => {
    try {
      const posts = await dispatch(getPostsAction('/' + refer + '/questions', {
        params: {
          id: subId,
        }
      }))
      if (posts) {
        setActivities(posts.questions)
      }
    } catch (error) {

    }
  }
  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          className="btn"
          data-toggle="modal"
          onClick={() => childRef.current.openModal()}
        >
          + Create New Activity
        </button>
      </div>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Index</th>
            <th>Activity</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((item, key) => (
            <tr key={'activity' + key}>
              <th>{item.index}</th>
              <div dangerouslySetInnerHTML={{ __html: item.question }} />
              <td className="align-top">
                <Badge bg="primary">{item.type ? 'Description':'Question'}</Badge>
              </td>
              <td className="align-top">
                <div className="d-flex justify-content-center gap-2">
                  <button title="Edit" className="btn btn-primary shadow btn sharp" onClick={() => childRef.current.openEdit(item)}>
                    <i className="fas fa-pencil-alt color-muted"></i>
                  </button>
                  <button
                    onClick={(e) => destroyActivity(e, item.id)}
                    title="delete"
                    className="btn btn-danger shadow btn sharp"
                  >
                    <i className="fa fa-close color-danger"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <BasicModal id={id} fetchActivities={fetchActivities} ref={childRef} refer={refer} />
    </>
  );
};
export default Activities;
