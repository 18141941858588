import React, { useEffect, useReducer, useRef, useState } from "react";
import { Nav, Tab, Modal, Table, Badge } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useParams } from "react-router-dom";
import BasicModal from "./BasicModal";

import { createPostAction, deletePostAction, getPostsAction, updatePostAction } from "../../../../store/actions/PostActions";
import config from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { Activities } from "../../Activities/Activities";

const init = false;
const reducer = (state, action) => {
  switch (action.type) {
    case "itemModal":
      return {
        ...state,
        itemModal: !state.itemModal,
        header: action.header,
        title: action.title,
        learningPathId: action.learningPathId,
        dataEdit: action.dataEdit
      };
    case "activitiesModal":
      return { ...state, activitiesModal: !state.activitiesModal, id: action.id };
    case "activityModal":
      return { ...state, activityModal: !state.activityModal, header: action.header, id: action.id };
    default:
      return state;
  }
};
function CourseDetails() {
  const [state, dispatch] = useReducer(reducer, init);
  const dispatch2 = useDispatch();
  const { id } = useParams();
  const childRef = useRef();
  const [pageData, setPageData] = useState([]);
  const [learningMaterials, setLearningMaterials] = useState([]);
  const [subModules, setSubModules] = useState([]);
  const [activities, setActivities] = useState([]);
  const [method, setMethod] = useState('')
  const img = config.apiUrl + 'uploads?image=practicums/';
  const methodList = ['', 'Virtual', 'Hybrid', 'On-site'];

  useEffect(() => {
    const fetchPracticum = async () => {
      try {
        const posts = await dispatch2(getPostsAction('/practicum/show/' + id));
        setPageData(posts)
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    const fetchLearningPath = async () => {
      try {
        const posts = await dispatch2(getPostsAction('/learning-path/index', {
          params: {
            pageSize: 8,
            pageNumber: 1,
          },
        }));
        setLearningMaterials(posts)
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    fetchPracticum();
    fetchLearningPath();
  }, [dispatch2]);

  const fetchSubModule = async (learningPathId) => {
    try {
      const posts = await dispatch2(getPostsAction('/sub-module/index', {
        params: {
          learningPathId: learningPathId,
          practicumId: id
        }
      }))
      if (posts) {
        setSubModules(posts);
      }

    } catch (error) {

    }
  }
  const submitSubModule = async (e, learningPathId) => {
    try {
      e.preventDefault();
      const [method, index, title] = e.target;
      const data = {
        index: index.value,
        sub_name: title.value,
        learningPathId: learningPathId,
        practicumId: id,
        method: method.value
      }
      const response = null
      if (state.dataEdit) {
        response = await dispatch2(updatePostAction('/sub-module/update', data,state.dataEdit.id))
      } else {
        response = await dispatch2(createPostAction('/sub-module/store', data))
      }
      if (response) {
        console.log(response)
        fetchSubModule(learningPathId)
        dispatch({ type: "itemModal" })
      }
    } catch (error) {

    }
  }
  const destroy = async (e, id, learningPathId) => {
    e.preventDefault();
    const response = await dispatch2(deletePostAction('/sub-module/delete/', id))
    if (response) {
      fetchSubModule(learningPathId)
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="course-content d-flex justify-content-between flex-wrap">
                <div>
                  <div className="d-flex align-items-center gap-3">
                    <h3 className="mb-0">{pageData.module_name}</h3>

                  </div>
                  {/* <ul className="d-flex align-items-center raiting my-0 flex-wrap">
                    {practicum.tags.map((item, key) => {
                      return <li key={key}>{item}</li>;
                    })}
                  </ul> */}
                </div>
                <div>
                  <button
                    onClick={() => childRef.current.openModal()}
                    className="btn btn-primary shadow btn sharp"
                    title="Edit"
                  >
                    <i className="fas fa-pencil-alt color-muted"></i>
                  </button>
                </div>
              </div>
              <div className="video-img style-1">
                <div className="view-demo">
                  <img src={img + pageData.image} alt="" />
                </div>
              </div>
              <div className="course-details-tab style-2 mt-4">
                <Tab.Container defaultActiveKey={"About"}>
                  <Nav className="nav nav-tabs tab-auto" id="nav-tab" role="tablist">
                    <Nav.Link className="nav-link" eventKey="About">
                      About
                    </Nav.Link>
                    <span className="nav-link">Learning Path:</span>
                    {learningMaterials.map((item, key) => (
                      <Nav.Link className="nav-link" key={'pathlist' + key} onClick={() => fetchSubModule(item.id)} eventKey={"Path" + item.id}>
                        {key + 1}
                      </Nav.Link>
                    ))}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="About">
                      <div className="about-content">
                        <div dangerouslySetInnerHTML={{ __html: pageData.des }} />
                      </div>
                    </Tab.Pane>
                    {learningMaterials.map((item, key) => (
                      <Tab.Pane key={'path' + key} eventKey={"Path" + item.id}>
                        <div className="about-content">
                          <div className="d-flex justify-content-between">
                            <h3 className="heading mb-3">{item.name}</h3>
                            <Link
                              className="btn"
                              data-toggle="modal"
                              onClick={() => dispatch({ type: "itemModal", header: "Create New Item", learningPathId: item.id })}
                              data-target="#itemModal"
                            >
                              + Create New Item
                            </Link>
                          </div>
                          <PerfectScrollbar
                            style={{ height: "370px" }}
                            id="DZ_W_TimeLine"
                            className="widget-timeline dlab-scroll height370 ps ps--active-y"
                          >
                            <ul className="timeline">
                              {subModules.length > 0 ? subModules.map((itemSub, key) => {
                                return (
                                  <li key={'sub' + itemSub.id}>
                                    <div className="timeline-badge primary"></div>
                                    <div className="timeline-panel text-muted">
                                      <div className="d-flex justify-content-between">
                                        <Link
                                          className=" align-items-center"
                                          data-toggle="modal"
                                          onClick={() => { dispatch({ type: "activitiesModal", id: itemSub.id }); }}
                                          data-target="#activitiesModal"
                                        >
                                          <span>{methodList[itemSub.method]}</span>
                                          <h6 className="mb-0">{itemSub.sub_name}</h6>
                                        </Link>
                                        <span className="d-flex justify-content-end">
                                          <Link
                                            className="me-2 btn btn-primary shadow btn sharp"
                                            title="Edit"
                                            data-toggle="modal"
                                            onClick={() => dispatch({ type: "itemModal", header: "Edit Item", dataEdit: itemSub })}
                                            data-target="#itemModal"
                                          >
                                            <i className="fas fa-pencil-alt color-muted"></i>
                                          </Link>
                                          <button
                                            onClick={(e) => destroy(e, itemSub.id, item.id)}
                                            title="delete"
                                            className="btn btn-danger shadow btn sharp"
                                          >
                                            <i className="fa fa-close color-danger"></i>
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                );
                              }) :
                                <div>No Data</div>
                              }
                            </ul>
                          </PerfectScrollbar>
                        </div>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Practicum */}
      <BasicModal ref={childRef} title="Edit Practicum" name={pageData.module_name} />
      {/* Modal Item */}
      <Modal show={state.itemModal} onHide={() => dispatch({ type: "itemModal" })} centered id="itemModal">
        <>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{state.header}</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => dispatch({ type: "itemModal" })}
              ></button>
            </div>
            <form onSubmit={(e) =>
              submitSubModule(e, state.learningPathId)
            }>
              <div className="modal-body">
                <div className="form-group mb-3">
                  <label htmlFor="method" className="form-label d-block">
                    Method
                  </label>
                  <select defaultValue={state.dataEdit?.method ?? "option"} id="method" className="form-control" name="method">
                    <option value="option" disabled>
                      Choose...
                    </option>
                    <option value="1">Virtual</option>
                    <option value="2">Hybrid</option>
                    <option value="3">On-Site</option>
                  </select>
                </div>
                <div className="form-group mb-3 row">
                  <div className="col-md-3">
                    <label htmlFor="method" className="form-label d-block">
                      Index
                    </label>
                    <input className="form-control" placeholder="Index" defaultValue={state.dataEdit?.index ?? ""} name="index" />
                  </div>
                  <div className="col">
                    <label htmlFor="method" className="form-label d-block">
                      Title
                    </label>
                    <input className="form-control" placeholder="Title" defaultValue={state.dataEdit?.sub_name ?? ""} name="title" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => dispatch({ type: "itemModal" })}>
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </>
      </Modal>
      {/* Modal Activities */}
      <Modal
        show={state.activitiesModal}
        onHide={() => dispatch({ type: "activitiesModal" })}
        className="fade bd-example-modal-lg"
        id="activitiesModal"
        size="xl"
      >
        <>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Activity</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => dispatch({ type: "activitiesModal" })}
              ></button>
            </div>
            <div className="modal-body">
              <Activities id={state.id} refer={"sub-module"} />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default CourseDetails;
