import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { createPostAction, getPostsAction } from "../../../store/actions/PostActions";
import { useDispatch } from "react-redux";
import AsyncSelect from 'react-select/async';
const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
  }));
  const dispatch = useDispatch();
  const [started_at, setStarted_at] = useState();
  const [ended_at, setEnded_at] = useState();
  const [time, setTime] = useState();
  const [type, setType] = useState();
  const [modules, setModules] = useState([]);
  const [moduleId, setModuleId] = useState();
  const [groupId, setGroupId] = useState();
  const [lectureId, setLectureId] = useState();
  const searchData = async (inputValue, refer) => {
    var params = {
      name: inputValue,
      pageSize: 8,
      pageNumber: 1,
    }
    if(refer === 'user'){
      params.role = 'lecture'
    }
    const response = await dispatch(
      getPostsAction(`/${refer}/index`, {
        params: params,
      })
    );

    if (response) {
      return response?.map((item) => {
        let label = '';
        switch (refer) {
          case type:
            label = item.module_name;
            break;
          case 'group':
            label = item.group_name;
            break;
          case 'user':
            label = `${item.first_name} ${item.last_name} (${item.username})`;
            break;
        }
        return {
          label: label,
          value: item.id,
        };
      });
    }
    return [];
  };

  const submit = async (e) => {
    e.preventDefault();
    const data = {
      started_at: started_at,
      ended_at: ended_at,
      time: time,
      lectureId:lectureId,
      practicumId: type === 'Practicum' ? moduleId : null,
      testId: type === 'Test' ? moduleId : null,
      groupId: groupId
    }
    const response = await dispatch(createPostAction('/schedule/store', data))
    if (response) {
      setModalBox(false)
      console.log(props)
      props.fetchData();
    }

  };
  const loadModule = (inputValue, callback) => {
    setTimeout(() => {
      searchData(inputValue, type).then((options) => {
        callback(options);
      });
    }, 1000)
  };
  const loadGroup = (inputValue, callback) => {
    setTimeout(() => {
      searchData(inputValue, 'group').then((options) => {
        callback(options);
      });
    }, 1000)
  };
  const loadLecture = (inputValue, callback) => {
    setTimeout(() => {
      searchData(inputValue, 'user').then((options) => {
        callback(options);
      });
    }, 1000)
  };
  const handleChange = (selected, type) => {
    switch (type) {
      case 'module':
        setModuleId(selected.value);
        break;
      case 'group':
        setGroupId(selected.value)
        break;
      case 'lecture':
        setLectureId(selected.value)
        break;
    }
  }
  return (
    <>
      <Modal onHide={setModalBox} size="" show={modalBox} centered>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Schedule</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalBox(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3 d-block">
              <label htmlFor="basic-url" className="form-label d-block">
                Type
              </label>
              <select defaultValue={"option"} value={type} className="form-control" onChange={(e) => setType(e.target.value)}>
                <option value="option" disabled>
                  Choose...
                </option>
                <option value="Practicum">Practicum</option>
                <option value="Test">Test</option>
              </select>
            </div>
            {type &&
              <div className="mb-3 d-block">
                <label htmlFor="basic-url" className="form-label d-block">
                  Search {type}
                </label>
                <AsyncSelect cacheOptions loadOptions={loadModule} defaultOptions onChange={(e) => handleChange(e, 'module')} />
              </div>
            }
            <div className="mb-3 d-block">
              <label htmlFor="basic-url" className="form-label d-block">
                Group
              </label>
              <AsyncSelect cacheOptions loadOptions={loadGroup} defaultOptions onChange={(e) => handleChange(e, 'group')} />
            </div>
            <div className="mb-3 d-block">
              <label htmlFor="basic-url" className="form-label d-block">
                Lecture
              </label>
              <AsyncSelect cacheOptions loadOptions={loadLecture} defaultOptions onChange={(e) => handleChange(e, 'lecture')} />
            </div>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridStartEndTime">
                <div className="mb-3">
                  <Form.Label>Time Start At</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    placeholder="08/01/2024 01:27 PM – 08/01/2024 01:27 PM"
                  />
                </div>
                <div>
                  <Form.Label>Time End At</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    placeholder="08/01/2024 01:27 PM – 08/01/2024 01:27 PM"
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridTestTime">
                <Form.Label>Time</Form.Label>
                <Form.Control type="text" placeholder="Minutes" onChange={(e) => setTime(e.target.value)}/>
              </Form.Group>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setModalBox(false)}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={(e)=>submit(e)}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});
export default BasicModal;
