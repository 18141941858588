import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import "react-modal-video/scss/modal-video.scss";
import course7 from "./../../../../images/course/7.jpg";
import CkEditorBlog from "../../LearningPath/CkEditorBlog";
import { Link, useLocation } from "react-router-dom";
import Meet from "./Meet";
import { socket } from "../../../../services/SocketService";
import { useDispatch } from "react-redux";
import { getPostsAction } from "../../../../store/actions/PostActions";

const practicum = {
  id: "1",
  image: course7,
  tags: ["Physics", "Bernoulli"],
  title: "Drone",
};

const progress = {
  finished: 3,
  total: 18,
  current: "Workspace",
};

function PracticumStart() {
  const user = JSON.parse(localStorage.getItem('userDetails'));
  const [index, setIndex] = useState(0);
  const [timer, setTimer] = useState(1800);
  const location = useLocation();
  const { moduleId, scheduleId } = location.state || {};
  const methodList = ['', 'Virtual', 'Hybrid', 'On-site'];
  const [active, setActive] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        }
        return prevTimer;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const dispatch = useDispatch();
  const [learningMaterials, setLearningMaterials] = useState([]);
  const [questions, setQuestions] = useState([]);
  const fetchQuestions = async (subId) => {
    try {
      const response = await dispatch(getPostsAction('/question/index', {
        params: {
          subModuleId: subId
        },
      }));
      if (response) {
        setQuestions(response)
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }
  const fetchData = async () => {
    try {
      const response = await dispatch(getPostsAction('/learning-path/step', {
        params: {
          practicumId: moduleId
        },
      }));
      if (response) {
        setLearningMaterials(response)
        setActive(response[0].sub_modules[0]?.id)
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  useEffect(() => {
    socket.emit('join_room', { username: user.username, room: scheduleId });
    fetchData();
  }, []);
  useEffect(() => {
    fetchQuestions(active)
  }, [active])
  return (
    <>
      <div className="row ">
        <div className="col-xl-3 col-xxl-3">
          <div className="card h-auto">
            <div className="card-body">
              <div>
                <div className="card-body pt-0 bg-white" >
                  <div className="progress-box">
                    <ProgressBar now={(progress.finished / progress.total) * 100} />
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-0 fs-14 font-w600">{progress.current}</h5>
                      <span className="font-w600">
                        {progress.finished}/{progress.total}
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="m-0 w-100" />
                <PerfectScrollbar style={{ height: "78vh" }}>
                  {learningMaterials.map((item, key) => (
                    <div key={key} className="about-content">
                      <h3 className="heading mb-3">{item.name}</h3>
                      <div
                        style={{ height: "auto" }}
                        id="DZ_W_TimeLine"
                        className="widget-timeline dlab-scroll height370 ps ps--active-y"
                      >
                        <ul className="timeline">
                          {item.sub_modules.map(({ id, sub_name, method, status = 0 }, index) => {
                            const badge = id === active ? "timeline-badge primary" : "timeline-badge dark";
                            return (
                              <li key={'subModules' + id} className="cursor-pointer" onClick={() => { setActive(id) }}>
                                <div className={badge}></div>
                                <div className="timeline-panel text-muted">
                                  <span>{methodList[method]}</span>
                                  <h6 className="mb-0">{sub_name}</h6>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ))}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-xxl-6">
          <div className="card h-auto">
            <div className="card-body">
              <PerfectScrollbar style={{ height: "79vh" }}>
                {questions.map((item, index) => {
                  return (
                    <div key={'question' + index}>
                      <div className="course-content d-flex justify-content-end flex-wrap">
                        <span className="text-muted">{item.type == 1 ? 'Description' : 'Question'}</span>
                      </div>
                      <div className="course-details-tab style-2 mt-4">
                        <div className="about-content" dangerouslySetInnerHTML={{ __html: item.question }} />
                      </div>
                      {item.type === 2 && (
                        <>
                          <div className="style-2 mt-4">
                            <h6>Insert your answer below:</h6>
                          </div>
                          <div className="form-group mb-4 col-12">
                            <CkEditorBlog />
                          </div>
                        </>
                      )}
                    </div>)
                })}
              </PerfectScrollbar>
            </div>
          </div>
          <div className="d-flex justify-content-between gap-2">
            <button className="btn btn-warning btn-block" onClick={() => setIndex(0)}>
              Previous
            </button>
            <button className="btn btn-primary btn-block" onClick={() => setIndex(1)}>
              Next
            </button>
          </div>
        </div>
        <div className="col-xl-2 col-xxl-3">
          <div className="bg-white h-auto rounded-0 rounded-top rounded-4">
            <div className="text-center border-0 justify-content-center">
              <h3 className="m-0">
                {Math.floor(timer / 60).toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
                  ":" +
                  (timer % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}
              </h3>
            </div>

          </div>
          <div className="bg-dark" style={{ height: '88%' }}>
            <Meet socket={socket} />
          </div>
          <Link
            to={`/student/practicum/${practicum.id}`}
            className="btn btn-danger btn-block mb-4 rounded-0 rounded-bottom rounded-4"
          >
            Exit
          </Link>
        </div>
      </div>
    </>
  );
}

export default PracticumStart;
