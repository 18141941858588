import React from "react";
import { Modal, Button, Table } from "react-bootstrap";

const StudentListModal = ({ show, handleClose, students }) => {
  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Student List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr className="text-dark">
              <th className="text-dark">#</th>
              <th className="text-dark">Name</th>
              <th className="text-dark">Username</th>
              <th className="text-dark">Email</th>
              <th className="text-dark">Prodi</th>
              <th className="text-dark">Universitas</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{student.user.first_name}</td>
                <td>{student.user.username}</td>
                <td>{student.user.email}</td>
                <td>{student.user.prodi}</td>
                <td>{student.user.universitas}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StudentListModal;
