
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";
import Avatar from "./Avatar";
import StudentListModal from "./StudentListModal"; // Import komponen modal
import { useState } from "react";
const ScheduleCard = ({ data, key, action, admin = true }) => {
    const startDate = new Date(data.startedAt);
    const endDate = new Date(data.endedAt);
    const formattedStartDate = format(startDate, "dd MMMM HH:mm");
    const formattedEndDate = format(endDate, "dd MMMM HH:mm");
    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <div className="col-xl-3 col-xxl-4 col-sm-6" key={key}>
            <div className="card shadow">
                <div className="card-body">
                    <h5 className="card-title">Group : {data.group.group_name}</h5>
                    <p className="card-text">Practicum : {data.practicum?.module_name}</p>
                    <div className="mb-3">
                        <strong>Students</strong>
                        <div className="d-flex mb-2 align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                {data.group.group_students.slice(0, 6).map((student, index) => (
                                    <OverlayTrigger
                                        key={index}
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-${index}`}>{student.user.username}</Tooltip>}
                                    >
                                        <div
                                            style={{
                                                width: "32px",
                                                height: "32px",
                                                marginLeft: index > 0 ? "-8px" : "0",
                                                border: "2px solid white",
                                                borderRadius: "50%",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    fontSize: "16px",
                                                }}
                                                name={student.user.username}
                                            />
                                        </div>
                                    </OverlayTrigger>
                                ))}
                                {data.group.group_students.length > 6 && (
                                    <div
                                        style={{
                                            width: "32px",
                                            height: "32px",
                                            marginLeft: "-8px",
                                            border: "2px solid white",
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "#f0f0f0",
                                            cursor: "pointer"
                                        }}
                                        onClick={handleShow}
                                    >
                                        more
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="mb-3">
                        <strong>Lecture</strong>
                        <div>
                            <span className="badge bg-primary">{data.lecture.username[0]}</span> {data.lecture.username}
                        </div>
                        <div>
                            <span className="fw-bolder">Role:</span> {data.lecture.roles}
                        </div>
                    </div>
                    <div className="mb-3">
                        <i className="bi bi-calendar"></i> {formattedStartDate} - {formattedEndDate}
                    </div>
                    <div className="mb-3">
                        <i className="bi bi-clock"></i> {data.time} Minutes
                    </div>
                    <div className="mb-3">
                        <span className="badge bg-success">Active</span>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row-reverse">
                        <div className={`d-flex justify-content-end`}>
                            {admin ? <>
                                <Link to="/admin/schedule" className="me-2 btn btn-primary shadow btn sharp" title="Edit">
                                    <i className="fas fa-pencil-alt color-muted"></i>
                                </Link>
                                <button
                                    onClick={(e) => action(e, data.id)}
                                    title="delete"
                                    className="btn btn-danger shadow btn sharp"
                                >
                                    <i className="fa fa-close color-danger"></i>
                                </button>
                            </> :
                                <button className="btn btn-primary" onClick={()=>action.current.openModal(data)}>Start</button>
                            }
                        </div>
                    </div>
                </div>
                {/* Memanggil Modal dari file lain */}
                <StudentListModal show={showModal} handleClose={handleClose} students={data.group.group_students} />
            </div>
        </div>
    );
};
export default ScheduleCard;